import '~/css/app.css'

import 'virtual:svg-icons-register'
import.meta.glob('../img/**/*')

// import 'lite-youtube-embed'
// import 'lite-vimeo-embed/module/lite-vimeo-embed'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Alpine from 'alpinejs'
import dialog from '@alpinejs/ui/src/dialog'
import popover from '@alpinejs/ui/src/popover'
import Intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'

gsap.registerPlugin(ScrollTrigger)

import gsapMarquee from './components/gsapMarquee'
import gsapImageHover from './components/gsapImageHover'
import splideSlider from './components/splideSlider'
import logoFader from './components/logoFader'
import gsapPixilate from './components/gsapPixilate'
import imageRandomizer from './components/imageRandomizer'
import modalManager from './components/modalManager'
import gsapLoader from './components/gsapLoader'
import videoPlayer from './components/videoPlayer'

export function init() {
  const APP = window.APP || {
    CONFIG: {},
  }

  window.Alpine = Alpine

  window.APP = {
    ...APP,
    Alpine,
    // Alpine components
    components: {
      modalManager,
    },
    // Other widgets / modules
    modules: {},
  }

  Alpine.plugin(Intersect)
  Alpine.plugin(dialog)
  Alpine.plugin(popover)
  Alpine.plugin(focus)
  Alpine.data('gsapMarquee', gsapMarquee)
  Alpine.data('gsapImageHover', gsapImageHover)
  Alpine.data('splideSlider', splideSlider)
  Alpine.data('logoFader', logoFader)
  Alpine.data('gsapPixilate', gsapPixilate)
  Alpine.data('imageRandomizer', imageRandomizer)
  Alpine.data('gsapLoader', gsapLoader)
  Alpine.data('siteMenu', () => ({
    menuOpen: false,

    toggle() {
      this.menuOpen = ! this.menuOpen
    },
  }))
  Alpine.data('modalManager', modalManager)
  Alpine.data('videoPlayer', videoPlayer)

  Alpine.start()

  let prev = 0
  let nav = document.querySelector('#navigation')

  window.addEventListener('scroll', function () {
    let scrollTop = window.scrollY || 0
    if (scrollTop < prev || scrollTop < 50) {
      nav.classList.remove('-translate-y-56', 'opacity-0')
    } else if (scrollTop > prev ) {
      nav.classList.add('-translate-y-56', 'opacity-0')
    }

    prev = scrollTop
  })
}

window.addEventListener('DOMContentLoaded', init)

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
