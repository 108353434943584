import Alpine from 'alpinejs'

export default ({ modalRef = null }) => {
  return {
    modalRef,
    $modal: null,
    $contentContainer: null,

    init() {
      this.$modal = this.$refs[this.modalRef]

      if (this.$modal) {
        this.$contentContainer = this.$modal.querySelector('[data-modal-content]')
        this.setupModalStore()
        this.bindModalEvents()
        this.bindModalEffect()
      }
    },

    openModal(templateSelector) {
      this.setModalContent(document.querySelector(templateSelector))
      this.$store.globalModalIsOpen = true
    },

    closeModal() {
      this.$store.globalModalIsOpen = false
    },

    setModalContent(template) {
      const content = template.content.firstElementChild.cloneNode(true)
      this.clearModalContent()
      this.$contentContainer.appendChild(content)
    },

    clearModalContent() {
      this.$contentContainer.innerHTML = ''
    },

    bindModalEvents() {
      this.$modal.addEventListener('close', () => {
        this.$store.globalModalIsOpen = false
      })

      this.$modal.addEventListener('click', (event) => {
        if (event.target.id === 'modal') {
          this.closeModal()
        }
      })
    },

    bindModalEffect() {
      Alpine.effect(() => {
        if (this.$modal) {
          const isOpen = this.$store.globalModalIsOpen

          if (isOpen) {
            this.$modal.showModal()
          } else {
            this.$modal.close()
            this.clearModalContent()
          }
        }
      })
    },

    setupModalStore() {
      Alpine.store('globalModalIsOpen', false)
    },
  }
}
