import gsap from 'gsap'
import debounce from 'debounce'

export default () => {
  return {
    init() {

      this.setupAnimation()
      let that = this;
      window.addEventListener('resize', function () {
        debounce(that.setupAnimation(), 300)
      })

    },
    setupAnimation(){
      console.log('setupAnimation');
      const sourceElement = this.$refs.logosOriginal
      const targetElement = this.$refs.logoFader

      targetElement.innerHTML = ''

      sourceElement.childNodes.forEach((child) => {
        targetElement.appendChild(child.cloneNode(true))
      })
      
      let images = gsap.utils.toArray('.js-image', this.$refs.logoFader)
      
      let colCount = this.getColumnsCount()

      this.getChunks(images, colCount).forEach((images, i) => {
        let wrapper = document.createElement('div')
        wrapper.classList.add('w-full', 'flex', 'justify-center')
        
        this.$refs.logoFader.appendChild(wrapper)
        
        // move elements into their own wrapper
        images.forEach((image) => {
          wrapper.appendChild(image)
          image.classList.add('-top-[25px]')
        })

        var logoTimeline = gsap.timeline({ repeat: -1, delay: i * 0.20 })
        var first = images[0]

        gsap.timeline().to(first, { autoAlpha: 1, top: '0px' })

        images.forEach((image, i) => {
          let next = images[i + 1]
          if (next) {
            logoTimeline
              .to(image, { autoAlpha: 0, top: '-15px' }, '+=2')
              .to(next, { autoAlpha: 1, top: '0px' }, '<')
          } else {
            logoTimeline
              .to(image, { autoAlpha: 0, top: '-15px'  }, '+=2')
              .to(first, { autoAlpha: 1, top: '0px'  }, '<')
          }
        })
      })
    },
    getColumnsCount() {
      return window.getComputedStyle(this.$refs.logoFader).getPropertyValue('grid-template-columns').split(' ').length
    },
    getChunks(images, columns) {
      let chunks = Array.from({ length: columns }, () => [])
      let currentIndex = 0
    
      // Distribute items across the chunks
      for (let i = 0; i < images.length; i++) {
        chunks[currentIndex].push(images[i])
        currentIndex = (currentIndex + 1) % columns
      }
    
      // Ensure no chunk has only one element if it's the last chunk
      for (let i = chunks.length - 1; i > 0; i--) {
        if (chunks[i].length === 1) {
          chunks[i - 1].push(chunks[i].pop())
        }
      }
    
      // Remove any empty chunks
      return chunks.filter(chunk => chunk.length > 0)
    }, 
  }
}
  

