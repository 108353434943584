import gsap from 'gsap';
import cookies from 'cookies.js';

export default () => {
  return {
    init() {

      if(cookies.get('loaded')) {
        this.$refs.gsapLoading.style.display = 'none'
        return 
      }

      let that = this
      let timeline = gsap.timeline()
      let mm = gsap.matchMedia();

      mm.add("(max-width: 767px)", (context) => {
        timeline.to(this.$refs.gsapMobileLoadingBar, {
          duration: 6,
          height: '100%',
          onUpdate() {
            that.$refs.gsapMobileLoadingText.innerText = Math.round(this.progress() * 100);
          }
        })
      });
      
      // Desktop
      mm.add("(min-width: 768px)", (context) => {
        timeline.to(this.$refs.gsapDesktopLoadingBar, {
          duration: 4.5,
          ease: 'power1.inOut',
          width: '100%',
          onUpdate() {
            that.$refs.gsapDesktopLoadingText.innerText = Math.round(this.progress() * 100);
          }
        })
      });

      // Always hide everything after the animation is done
      timeline.to(this.$refs.gsapLoading,{
        opacity: 0,
        ease: 'power1.inOut',
        delay: 0.5,
        duration: 0.4,
        onComplete: () => {
          this.$refs.gsapLoading.style.display = 'none'
        }
      })

      cookies.set('loaded', 'true', { expires: 0.5  });
    }
  }
}