import videojs from 'video.js'

export default () => {
  return {
    init() {
      if (this.$refs.videoJs) {
        videojs(this.$refs.videoJs, {
          controls: true,
          autoplay: false,
          preload: 'none',
          loop: true,
          muted: true,
          playsinline: true,
          controlBar: {
            children: [
              'playToggle',
              'currentTimeDisplay',
              'timeDivider',
              'durationDisplay',
            ],
          },
        })
      }
    }
  }
}
